import { AxiosError } from 'axios';

export const axiosErrorHandler = (error: AxiosError) => {
  const request = error?.request;
  const response = error?.response;
  if (response) {
    const data = response.data;
    const status = response.status;

    return {
      data,
      status,
    };
  } else if (request) {
    //request sent but no response received
    return {
      data: {
        message:
          error?.message || 'possible server time out - no response received',
      },
      status: 408,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    return {
      data: {
        message:
          error?.message || 'something went wrong while setting up request',
      },
      status: 418,
    };
  }
};
